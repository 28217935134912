import { Box } from "@mui/material";
import "./App.css";
import Kulpay from "./Kulpay";
function Footer() {
  return (
    <Box sx={{my:"5rem"}}>
           <footer
        className="footer-area bg-img bg-overlay-one"
        style={{
          backgroundImage:
            "url(https://klptchain.com/mining/assets/images/frontend/footer/6558bc7c809171700314236.png)",
        }}
      >
        <div className="footer-top py-50 container">
          <div className="row justify-content-center gy-5">
            <div className="col-xl-4 col-sm-6 pe-lg-5">
              <div className="footer-item">
                <div className="footer-item__logo">
                  <a href="https://kulpaytoken.com/mining">
                    {" "}
                    <img
                      src="https://klptchain.com/mining/assets/images/logoIcon/logo.png"
                      alt="KulPayToken"
                    />
                  </a>
                </div>
                <p className="footer-item__desc">
                  Step into the essence of KLPT Token – your pathway to the
                  forefront of decentralized finance (DeFi). Within, explore a
                  realm of opportunities as we guide you through a journey of
                  financial liberation and pioneering innovation.
                </p>
              </div>
            </div>
            <div className="col-xl-2 col-sm-6">
              <div className="footer-item">
                <h5 className="footer-item__title"> Quick Links </h5>
                <ul className="footer-menu">
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/about-us"
                    >
                      About
                    </a>
                  </li>
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/mining-plans"
                    >
                      Plans
                    </a>
                  </li>
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/blogs"
                    >
                      Blog
                    </a>
                  </li>
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/contact"
                    >
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="footer-item">
                <h5 className="footer-item__title">Useful Links</h5>
                <ul className="footer-menu">
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/policy/privacy-policy/162"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/policy/terms-of-service/163"
                    >
                      Terms of Service
                    </a>
                  </li>
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/policy/cookie-policy/164"
                    >
                      Cookie Policy
                    </a>
                  </li>
                  <li className="footer-menu__item">
                    <a
                      className="footer-menu__link"
                      href="https://klptchain.com/mining/policy/usage-policy/165"
                    >
                      Usage Policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="footer-item">
                <h5 className="footer-item__title">Contact Info </h5>
                <ul className="footer-contact-menu">
                  <li className="footer-contact-menu__item">
                    <div className="footer-contact-menu__item-icon">
                      <i className="las la-phone" />
                    </div>
                    <div className="footer-contact-menu__item-content">
                      <p>
                        <a href="tel:+ 001 2166 5645">+ 001 2166 5645</a>
                      </p>
                    </div>
                  </li>
                  <li className="footer-contact-menu__item">
                    <div className="footer-contact-menu__item-icon">
                      <i className="lar la-envelope" />
                    </div>
                    <div className="footer-contact-menu__item-content">
                      <p>
                        <a href="mailto:support@kulpaytoken.com">
                          support@kulpaytoken.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="footer-contact-menu__item">
                    <div className="footer-contact-menu__item-icon">
                      <i className="las la-map-marker-alt" />
                    </div>
                    <div className="footer-contact-menu__item-content">
                      <p>Kulpaytoken Head Office, 10345 driveway USA</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer py-3">
          <div className="container">
            <div className="row gy-3">
              <div className="col-md-12 text-center">
                <div className="bottom-footer-text text-white">
                  Copyright © 2024{" "}
                  <a
                    className="text--base"
                    href="https://kulpaytoken.com/mining"
                  >
                    KulPayToken
                  </a>
                  . All rights reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Box>
  );
}

export default Footer;
