import { Box } from "@mui/material";
import "./App.css";

import Footer from "./Footer";
function Error() {
  return (


    <div>
      {/* Hello world */}

      <link
    type="text/css"
    href="https://klptchain.com/mining/assets/templates/dark/css/color.php?color=de9805"
    rel="stylesheet"
  />
      <div className="preloader" style={{ display: "none" }}>
        <div className="loader-p" />
      </div>
      <div className="sidebar-overlay" />
      <header className="header-bottom" id="header">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            <a
              className="navbar-brand logo"
              href="https://kulpaytoken.com/mining"
            >
              <img
                src="https://klptchain.com/mining/assets/images/logoIcon/logo.png"
                alt="Logo"
              />
            </a>
         
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav nav-menu m-auto">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    href="https://klptchain.com/mining/mining-plans"
                  >
                    Mining Plans
                  </a>
                </li>
              </ul>
              <div className="langauge-registration d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-sm-0 user-btn-group flex-wrap gap-1">
                

                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <section
        className="breadcumb bg-overlay-one bg-img"
        style={{
          backgroundImage:
            "url(https://klptchain.com/mining/assets/images/frontend/breadcrumb/63709bdbb58aa1668324315.png)",
        }}
      >
     
      </section>

      <Box  sx={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", my:"5rem"}}>
    <h1>Not Authorized for payment</h1>
    <h3><a href="https://kulpaytoken.com/user/login">Login</a> your Kulpay Account</h3>

    </Box>
  
  <Footer />

    </div>

  );
}

export default Error;
