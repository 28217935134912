import { useEffect } from 'react';
import { useWeb3Modal } from '@web3modal/ethers/react'


function Button() {

  const { open, close } = useWeb3Modal()


 
  return <button style={{ marginTop:"0.5rem", backgroundColor:"#FFBB00", color:"#000", fontWeight:"3000" , borderRadius:"10px", padding:"0.5rem" }} 
  onClick={()=>{open()}}
  >CONNECT WALLET</button>
}

export default Button