import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';


import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';



import "./index.css";


import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '89d6165cde4033924f9d32292ae2f61c'

// 2. Set chains
// const mainnet = {
//   chainId: 80001,
//   name: 'Mumbai',
//   currency: 'MATIC',
//   explorerUrl: 'https://mumbai.polygonscan.com',
//   rpcUrl: 'https://rpc-mumbai.maticvigil.com'
// }


// 2. Set chains
const mainnet = {
  chainId: 56,
  name: 'Smart Cain',
  currency: 'BSC',
  explorerUrl: 'https://bscscan.com',
  rpcUrl: 'https://bsc-dataseed4.ninicoin.io'
}

// 3. Create modal
const metadata = {
  name: 'My Website',
  description: 'My Payment Gateway',
  url: 'paymentgateways.kulpaytoken.com',
  icons: ['']
}

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId
})



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
   
   
 
   
   
  
    <div>
 
    <App /> 
    </div>

   
 

  
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();