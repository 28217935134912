import { Routes, Route } from "react-router-dom";


import KULPAY from "./Kulpay";
import Error from "./Error";
import Withdrawal from "./Withdrawal";




function App() {
  return (
    <Routes>
 <Route path="/withdrawal/:username" element={<Withdrawal/>} />
<Route path="/" element={<Error/>} />
      
      <Route path="/:username" element={<KULPAY />} >
      <Route path="/:username/:id" element={<KULPAY />} />
   

      </Route>
     



        

     
     
    </Routes>
  );
}

export default App;